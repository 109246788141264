import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'apex-web/lib/components/common/Modal/Modal';
import APLabelWithText from 'apex-web/lib/components/common/APLabelWithText';
import APIcon from 'apex-web/lib/components/common/APIcon';
import { formatDateTime } from 'apex-web/lib/helpers/dateHelper';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { templateConfirmRenderer } from 'apex-web/lib/helpers/formTemplateHelper';

import './FiatWithdrawModalComponent.css';
import Email from './email.png';

let EmailPromptModal = ({ context, onClick, isOpen, close }) =>
  React.createElement(
    Modal,
    {
      isOpen: isOpen,
      title: React.createElement(
        'div',
        { className: fiatWithdrawModalClasses('header-content') },

        React.createElement('img', {
          src: Email,
          alt: 'Email'
        }),
        React.createElement(
          'div',
          { className: fiatWithdrawModalClasses('header-title') },
          'Check your mail inbox'
        )
      ),
      close: close,
      footer: {
        buttonText: context.t('Confirm'),
        buttonStyle: 'additive',
        onClick
      },
      customClass: fiatWithdrawModalClasses()
    },
    React.createElement(
      'div',
      { className: fiatWithdrawModalClasses('title') },
      'Please check your email inbox or the “SPAM” folder for 2-FA confirmation, your withdraw will be processed after we have received your confirmation via email.'
    ),
    React.createElement(
      'div',
      { className: 'check-spam-mail-text-1' },
      "Please check your SPAM/JUNK folder if you don't see our email in your inbox."
    )
  );

var fiatWithdrawModalClasses = getBEMClasses('fiat-withdraw-modal');

var FiatWithdrawModalComponent = function FiatWithdrawModalComponent(
  props,
  context
) {
  var template = props.template,
    payload = props.payload,
    emailPromptOpen = props.emailPromptOpen,
    setEmailPromptOpen = props.setEmailPromptOpen;
  var ProductSymbol = payload.ProductSymbol,
    ProductFullName = payload.ProductFullName,
    Amount = payload.Amount;

  return React.createElement(
    Modal,
    {
      isOpen: props.isOpen,
      title: React.createElement(
        'div',
        { className: fiatWithdrawModalClasses('header-content') },
        React.createElement(APIcon, {
          name: 'withdrawWithBorder',
          customClass: fiatWithdrawModalClasses('icon')
        }),
        React.createElement(
          'div',
          { className: fiatWithdrawModalClasses('header-title') },
          context.t('Withdraw') + ' ' + ProductSymbol + ' ' + ProductFullName
        )
      ),
      close: () => {},
      footer: {
        buttonText: context.t('Confirm'),
        buttonStyle: 'additive',
        onClick: function onClick() {
          //setEmailPromptOpen(true);
          props.submitWithdraw(props.payload);
        }
      },
      customClass: fiatWithdrawModalClasses()
    },
    React.createElement(
      'div',
      { className: fiatWithdrawModalClasses('title') },
      context.t('Confirmation')
    ),
    React.createElement(
      'div',
      { className: fiatWithdrawModalClasses() },
      React.createElement(APLabelWithText, {
        label: context.t('Amount to Withdraw'),
        text: Amount + ' ' + ProductSymbol,
        customClass: fiatWithdrawModalClasses()
      }),
      templateConfirmRenderer(
        template,
        payload,
        fiatWithdrawModalClasses,
        context
      ),
      React.createElement(APLabelWithText, {
        label: context.t('Fee'),
        text: props.fee,
        customClass: fiatWithdrawModalClasses()
      }),
      React.createElement(APLabelWithText, {
        label: context.t('Time'),
        text: formatDateTime(new Date()),
        customClass: fiatWithdrawModalClasses()
      })
    )
    /*React.createElement(
      EmailPromptModal, {
        onClick: () => (
          setEmailPromptOpen(false),
          props.submitWithdraw(props.payload)
        ),
        context,
        close: props.close,
        isOpen: emailPromptOpen,
      }
    )*/
  );
};

FiatWithdrawModalComponent.propTypes = {
  payload: PropTypes.object,
  close: PropTypes.func.isRequired
};

FiatWithdrawModalComponent.defaultProps = {
  payload: {
    FullName: '',
    Amount: '',
    BankAccountName: '',
    BankAccountNumber: '',
    BankAddress: '',
    Comment: ''
  }
};

FiatWithdrawModalComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default FiatWithdrawModalComponent;
